<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['firstSpotsOccurrence'])">
      <multi-answer-question
        id="first-spots-occurrence"
        title="label.firstSpotsOccurrence"
        :value="firstSpotsOccurrence"
        :options="spotOccurrenceOptions"
        dysfunction="skin-pigmentation"
        :max-answers="3"
        @input="onFieldChange('firstSpotsOccurrence', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['facialSpotLocations'])"
    >
      <face-zone-multi-select
        id="facial-spot-locations"
        title="label.facialHyperpigmentationSpotLocations"
        :value="facialSpotLocations"
        :options="$options.spotLocationsOptions"
        :gender="gender"
        hint="label.selectAllRegionsThatApply"
        field-name="pigmentation"
        @input="onFieldChange('facialSpotLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  FIRST_SPOTS_OCCURRENCE_OPTIONS,
  SPOT_LOCATIONS_OPTIONS
} from '@/modules/questionnaire/constants/steps/skinPigmentation';

export default {
  name: 'TypeTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion, FaceZoneMultiSelect },
  mixins: [stepTemplateMixin],
  spotLocationsOptions: SPOT_LOCATIONS_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    firstSpotsOccurrence: {
      type: Array,
      default: () => []
    },
    spotOccurrenceValues: {
      type: Array,
      required: true
    },
    facialSpotLocations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    spotOccurrenceOptions() {
      return FIRST_SPOTS_OCCURRENCE_OPTIONS.filter(({ value }) =>
        this.spotOccurrenceValues.includes(value)
      );
    }
  }
};
</script>

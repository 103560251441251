import {
  FACIAL_SPOT_LOCATIONS,
  SPOT_OCCURRENCE,
  SPOT_SHAPE
} from '@/modules/questionnaire/api/constants';

export const FIRST_SPOTS_OCCURRENCE_OPTIONS = [
  { value: SPOT_OCCURRENCE.WHEN_A_CHILD, text: 'firstSpotsOccurrence.whenAChild' },
  { value: SPOT_OCCURRENCE.AT_MIDDLE_AGE, text: 'firstSpotsOccurrence.atMiddleAge' },
  { value: SPOT_OCCURRENCE.DURING_PREGNANCY, text: 'firstSpotsOccurrence.duringPregnancy' },
  { value: SPOT_OCCURRENCE.AFTER_STARTING_HRT, text: 'firstSpotsOccurrence.afterStartingHrt' },
  {
    value: SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL,
    text: 'firstSpotsOccurrence.afterStartingBirthControl'
  },
  { value: SPOT_OCCURRENCE.AFTER_AN_INJURY, text: 'firstSpotsOccurrence.afterAnInjury' },
  {
    value: SPOT_OCCURRENCE.AFTER_PROLONGED_SUN_EXPOSURE,
    text: 'firstSpotsOccurrence.afterProlongedSunExposure'
  },
  {
    value: 'unsure',
    text: 'firstSpotsOccurrence.unsure',
    overridesAll: true
  }
];

export const SPOT_LOCATIONS_OPTIONS = [
  {
    value: FACIAL_SPOT_LOCATIONS.UPPER_CHEEKS,
    text: 'facialHyperpigmentationSpotLocation.upperCheeks'
  },
  {
    value: FACIAL_SPOT_LOCATIONS.LOWER_CHEEKS,
    text: 'facialHyperpigmentationSpotLocation.lowerCheeks'
  },
  { value: FACIAL_SPOT_LOCATIONS.FOREHEAD, text: 'facialHyperpigmentationSpotLocation.forehead' },
  { value: FACIAL_SPOT_LOCATIONS.NOSE, text: 'facialHyperpigmentationSpotLocation.nose' },
  { value: FACIAL_SPOT_LOCATIONS.CHIN, text: 'facialHyperpigmentationSpotLocation.chin' },
  { value: FACIAL_SPOT_LOCATIONS.MOUTH, text: 'facialHyperpigmentationSpotLocation.mouth' }
];

export const SPOT_SHAPE_OPTIONS = [
  { value: SPOT_SHAPE.ROUND_AND_SMALL },
  {
    value: SPOT_SHAPE.IRREGULAR_AND_LARGE
  }
];
